.wrap-news-box {
	display: block;
	&:hover {
		.news-box-image__item {
			transform: scale(1.07);
		}
	}
}

.news-box-image {
	overflow: hidden;
	width: 100%;
	height: 149px;
}

.news-box-image__item {
	width: 100%;
	height: 149px;
	transition: all 0.6s;
	background-position: center center;
	background-size: cover;
}

.news-box-data {
	&__title {
		margin-bottom: 25px;
		color: #415667;
		font-size: 24px;
		font-weight: 800;
		line-height: 1;
		@include respond-to('extra-small') {
			margin: 10px 0 15px;
			font-size: 22px;
		}
	}
	&__date {
		margin-bottom: 10px;
		color: #cccccc;
		font-size: 14px;
	}
	&__content {
		//min-height: 95px;
		//max-height: 105px;
		overflow: hidden;
		text-align: justify;
		& > p {
			margin: 0;
			color: #415667;
			font-size: 18px;
			line-height: 1.2;
		}
	}
}

.news-box__btn {
	display: inline-block;
	margin-top: 30px;
	padding-top: 8px;
	border-top: 2px solid #f7991b;
	color: #f7991b;
	font-size: 18px;
	font-weight: 800;
	&:hover,
	&:active,
	&:focus {
		color: #f7991b;
	}
}
