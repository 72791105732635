.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background: #fff;
	box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	transition: all 0.4s ease;
}
.header-desktop-top {
  height: 35px;
  background: #002e62;
	@include respond-to('medium') {
		display: none;
  }
  
}



.header-desktop {
  background: #eeeeee;
	height: 100px;
	@include respond-to('medium') {
		display: none;
	}
}

.logo {
  display: inline-block;
  position: relative;
  float: left;
  top: 20px;
}
.header-desktop-top .main-navi {
	display: inline-block;
	& > ul {
		display: inline-block;
		list-style: none;
		margin: side-values(35px 20px 0 0);
		padding: 0;
		& > li {
			float: right;
			margin-#{$left}: 3px;
			position: relative;
			& > a {
				font-size: 19px;
        color: #000;
        font-weight: 600;
				padding: 3px 10px 33px;
				display: block;
				border: 1px solid transparent;
				&:hover {
          text-decoration: none;
          &::after {
            // content: '';
            // position: absolute;
            // bottom: 0;
            // left: 50%;
            // transform: translateX(-50%);
            // width: 0;
            // height: 0;
            // border-style: solid;
            // border-width: 0 28px 15px;
            // border-color: transparent transparent #002e62 transparent;
          }
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					text-decoration: none;
          &::after {
            // content: '';
            // position: absolute;
            // bottom: 0;
            // left: 50%;
            // transform: translateX(-50%);
            // width: 0;
            // height: 0;
            // border-style: solid;
            // border-width: 0 28px 15px;
            // border-color: transparent transparent #002e62 transparent;
          }
				}
			}
		}
	}
}



.header-desktop .main-navi {
	display: inline-block;
	& > ul {
		display: inline-block;
		list-style: none;
		margin: side-values(35px 20px 0 0);
		padding: 0;
		& > li {
			float: $right;
			margin-#{$left}: 3px;
			position: relative;
			& > a {
				font-size: 19px;
        color: #000;
        font-weight: 600;
				padding: 3px 10px 33px;
				display: block;
				border: 1px solid transparent;
				&:hover {
          text-decoration: none;
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 28px 15px;
            border-color: transparent transparent #002e62 transparent;
          }
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					text-decoration: none;
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 28px 15px;
            border-color: transparent transparent #002e62 transparent;
          }
				}
			}
		}
	}
}

.wrap-lang {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-top: 35px;
	// &::before {
	// 	font-family: "Font Awesome 5 Pro";
	// 	font-weight: 300;
	// 	content: "\f107";
	// }
	& > i {
	  position: absolute;
	  top: 26%;
	  #{$right}: 10px;
	  pointer-events: none;
	  color: #000;
	}
}

.select-lang {
	padding: 5px 33px 6px 10px;
	font-size: 16px;
	color: #000;
	background-color: transparent;
	border: 1px solid #000;
	cursor: pointer;
	border-radius: 3px;
	background-image: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	&:focus {
		outline: none;
	}
	& > option {
		color: #000;
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 10px;
		float: $left;
		& > img {
			width: 200px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: #000;
			font-size: 32px;
		}
	}
}

.main-header.scrolling {
	// .header-desktop {
	// 	height: 80px;
	// }
	// .main-navi {
	// 	& > ul {
	// 		margin-top: 25px;
	// 	}
	// }
	// .wrap-lang {
	// 	margin-top: 25px;
	// }
	// .logo {
	// 	top: 15px;
	// }
}





.mtop{
  margin: 0px 10px 0 0 !important; 
}
.mtop a{
  color: #fff !important;
}


.notdisplay{
	display: none !important;
} 

.leftfloat{
	float: $left; 
	width: 38%;
}
.fonts13{
	font-size: 13px;
}
.inputup{
	outline: none !important;
	color: #fff;
	background: transparent;
	border: none;
	border-bottom: 1px solid #fff;
	font-size: 15px;
}

.inputup::placeholder { 	
  color: #fff;
}
.mybtn{
	color: #002e62;
	margin-top: 6px;
	font-size: 14px;
	padding: 1px 12px;
	font-weight: 700;
	border-radius: 0;
}