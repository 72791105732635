.wrapper-all-content {
	background-color: #eeeeee;
	position: relative;
	width: 100%;
}

.homepage__buttons {
	padding: 20px 0;
}

.background-linarg{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,b0b1b5+100 */
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(176,177,181,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(176,177,181,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(176,177,181,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#b0b1b5',GradientType=0 ); /* IE6-9 */
}
.middle-text{
  padding: 40px 0;
}
.hr-homepage{
  height: 19px;
  background: #002e62;
  border-top: 1px solid #fff;
  border-bottom: 12px solid #b0b1b5;
}