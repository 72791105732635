.hr-content-article{
  border-top: 1px solid #aaaaaa;
}
.btn-more-info{
  border-top: 1px solid #002e62;
  font-size: 22px;
  color: #002e62;
  font-weight: 700;
  margin-bottom: 15px;
  text-decoration: none;
}

.btn-more-info:hover{
  text-decoration: none;
}
.main-text , .date{
  padding: 10px 0;
  color: #6f6f6f;
  font-size: 19px;
}
.text-samaery{
  padding: 10px 0;
  color: #002e62;
  font-size: 18px;
}
.article-item{
  margin: 15px 0;
}
.title-main-article{
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  color: #002e62;
  margin: 10px 0;
}
.ul-side-li{ 
  cursor: pointer;
}