.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 450px;
	margin-top: 140px;
	@include respond-to('medium') {
		min-height: 420px;
		margin-top: 75px;
	}
	@include respond-to('small') {
		min-height: initial;
	}
}

.banner-top-content__image {
	background-position: center center;
	background-size: cover;
	height: 450px;
	-webkit-box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
	box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
	@include respond-to('medium') {
		height: 420px;
	}
	@include respond-to('small') {
		display: none;
	}
}

.banner-top-content-data {
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform(translate(-50%, -50%));
	z-index: 9;
	@include respond-to('medium') {
		top: 25%;
		#{$left}: 0;
		@include transform(none);
		#{$right}: 0;
		text-align: center;
	}
	@include respond-to('small') {
		display: none;
	}
}

.banner-top-content__title {
	font-size: 50px;
	font-weight: 800;
	color: #fff;
	text-align: center;
	margin: 75px 0 5px;
	@include respond-to('large') {
		font-size: 42px;
	}
	@include respond-to('medium') {
		margin: 0 0 30px;
		margin-bottom: 0;
	}
	@include respond-to('small') {
		font-size: 32px;
	}
	@include respond-to('extra-small') {
		font-size: 28px;
		margin-bottom: 10px;
	}
}

.banner-top-content__text {
	& > p {
		font-size: 34px;
		color: #fff;
		text-align: center;
		margin: 0;
		@include respond-to('huge') {
			font-size: 28px;
		}
		@include respond-to('small') {
			font-size: 24px;
			line-height: 1.1;
		}
		@include respond-to('small') {
			font-size: 20px;
		}
	}
}

.not-content-banner {
	height: 140px;
	@include respond-to('medium') {
		height: 75px;
	}
}