.wrapper-all-content {
	.wrap-content_p-0 {
		padding-bottom: 0;
	}
}

.title-block {
	position: relative;
	margin: 35px 0 0;
	text-align: center;
	&::after {
		display: block;
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: 1px;
		width: 100%;
		background-color: #aaa;
	}
	& .title {
		position: relative;
		display: inline-block;
		margin: 0;
		padding: 0 45px;
		text-align: center;
		background-color: #fff;
		color: $accent;
		font-size: 30px;
		font-weight: 700;
		z-index: 1;
		@include respond-to('medium') {
			padding: 0 30px;
			font-size: 30px;
			font-weight: 500;
		}
		@include respond-to('small') {
			padding: 0 20px;
		}
	}
}



.product-code {
	margin-bottom: 20px;
	text-align: center;
	font-weight: 300;
}

.product-item-description {
	&__text {
		margin-bottom: 40px;
		padding-#{$left}: 30px;
		@include respond-to('medium') {
			padding-#{$left}: 0px;
			margin-bottom: 20px;
		}
		@include respond-to('small') {
			font-size: 18px;
			text-align: justify;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
		}
	}
	&__prise-block {
		margin-bottom: 40px;
		@include respond-to('medium') {
			margin-bottom: 20px;
		}
		& .old-price {
			&::before {
				width: 100%;
			}
		}
	}
	&__cart-block {
		float: $right;
		@include respond-to('extra-small') {
			float: none;
		}
	}
	&__lock {
		float: $right;
		margin-top: 15px;
		@include respond-to('extra-small') {
			float: none;
		}
	}
}

.old-value,
.new-value {
	&::after {
		content: ' ₪ ';
	}
	// &::before {
	// 	content: if-rtl(none, ' ₪ ');
	// }
}

.old-price {
	position: relative;
	font-size: $font-size-small;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		width: 93%;
		height: 1px;
		background-color: $text;
	}
}

.new-price {
	font-size: 25px;
	font-weight: 600;
	color: $accent;
	& .new-value {
		color: #000;
		font-weight: 600;
	}
}

.cart-button {
	position: relative;
	padding: 11px 20px;
	background-color: $accent;
	color: #fff;
	border: none;
	border-radius: 4px;
	font-size: $font-size-medium;
	font-weight: 700;
	@include respond-to('extra-small') {
		display: block;
		margin: 0 auto;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		#{$right}: 70px;
		width: 1px;
		height: 100%;
		background-color: #fff;
	}
	&:active,
	&:focus {
		@include btn-effect-active;
	}
	&__icon {
		display: inline-block;
		position: relative;
		top: 3px;
		height: 29px;
		width: 32px;
		margin-#{$left}: 40px;
		background-image: url(../images/cart-icon.png);
		background-position: center;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}
}

.lock {
	vertical-align: middle;
	margin-#{$right}: 42px;
	@include respond-to('extra-small') {
		margin-#{$right}: 0px;
	}
	&__icon {
		position: relative;
		top: -7px;
		display: inline-block;
	}
	&__text {
		display: inline-block;
		max-width: 80px;
		margin-#{$right}: 10px;
		line-height: 1.2;
		color: #000;
		font-size: $font-size-small;
		font-weight: 500;
	}
}

.product-item-gallery-block {
	position: relative;
	text-align: $left;
	@include respond-to('medium') {
		text-align: center;
		margin: 0 auto 15px;
		max-width: 450px;
	}
	& .product-box__label {
		top: -5px;
		left: -5px;
	}
}

.product-item-gallery {
	padding: 35px;
	float: left;
	position: relative;
	border-radius: 6px;
	border: 1px solid #dedede;
	overflow: hidden;
	text-align: center;
	@include respond-to('medium') {
		float: none;
		display: block;

	}
	& img {
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}
}

.label-zoom {
	display: block;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 93px;
	height: 84px;
	border-bottom-right-radius: 6px;
	background-image: url(../images/item-zoom.png);
	z-index: 2;
}

.hr-item {
	margin: 80px 0 0;
	padding: 0;
	border-top: 1px solid #dedede;
	@include respond-to('medium') {
		margin: 40px 0 0;
	}
	@include respond-to('small') {
		margin: 30px 0 0;
	}
	@include respond-to('extra-small') {
		margin: 20px 0 0;
	}
}

.other-propositions {
	margin: 35px 0;
	font-size: 30px;
	font-weight: 700;
	color: $accent;
	@include respond-to('medium') {
		margin: 25px 0px;
	}
	@include respond-to('small') {
		margin: 15px 0px;
		font-weight: 400;
	}
}

.lg-outer {
	direction: ltr;
}
