footer {
	width: 100%;
	position: relative;
	background-color: #eeeeee;
	z-index: 1;
	padding: 50px 0 20px;
	@include respond-to('large') {
		padding: 20px 0;
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #bebebe;
	margin-top: 40px;
	margin-bottom: 20px;
}

.credit-info-txt {
	display: inline-block;
	@include respond-to('medium') {
		display: block;
		text-align: center;
		margin-bottom: 10px;
	}
	@include respond-to('small') {
		margin-right: 0;
	}
	& > p {
		font-size: 16px;
		color: #000;
		font-weight: 300;
		margin: 0;
		@include respond-to('extra-small') {
			font-size: 14px;
		}
	}
}

.wrap-social {
	position: relative;
	display: inline-block;
	margin-#{$right}: 25px;
	@include respond-to('large') {
		margin-#{$right}: 20px;
	}
	@include respond-to('medium') {
		display: block;
		text-align: center;
		margin: 0;
		margin-bottom: 10px;
	}
	& > a {
		& > i {
			color: #777777;
			margin-#{$left}: 15px;
		}
		&:hover {
			& > .fa-twitter {
				color: $clr-twitter;
			}
			& > .fa-youtube {
				color: $clr-youtube;
			}
			& > .fa-facebook-f {
				color: $clr-facebook;
			}
		}
	}
}
