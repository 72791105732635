.wrap-three-cat-box {
	&:nth-child(even) {
		.col-md-6 {
			float: $left;
		}
	}
}

.catgory-item{
  color: #002e62;
  text-align: center;
}
.catgory-item-img{
  width: 100%;
  max-width: 147px;
  max-height: 147px;
}
.catgory-item-title{
  font-size: 25px;
  padding: 2px 2px  0px;
  font-weight: bold;
  min-height: 30px;
}
.catgory-item-title2{
  font-size: 20px;
  padding: 2px 2px ;
  font-weight: bold;
  min-height: 30px;
}
.catgory-item-text{
  font-size: 18px;
  min-height: 65px;
  padding-bottom: 15px;
}
.category-big-img{
  height: auto;
}
.category-right .catgory-item-title{
  color: #002e62;
}
.category-right .catgory-item-subtitle{
  color: #002e62;
  font-size: 20px;
  font-weight: bold;
}
a.catgory-item{
  text-decoration: none;
}
a.catgory-item:hover{
  text-decoration: none;
}