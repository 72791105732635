.box-category {
	width: 100%;
	max-width: 440px;
	height: 300px;
	position: relative;
	overflow: hidden;
	display: block;
	margin-bottom: 40px;
	&:hover {
		& .box-category__image {
			@include transform(scale(1.07));
		}
	}
	@include respond-to('small') {
		max-width: none;
		height: auto;
		margin-bottom: 15px;
	}

	&__image {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		width: 100%;
		height: 300px;
		@include transition(all 0.7s);
		@include respond-to('small') {
			height: 130px;
		}
	}

	&__wrap-title {
		// height: 50px;
		position: absolute;
		background-color: rgba(255, 255, 255, 0.9);
		padding: side-values(5px 15px 5px 20px);
		margin: 0 auto;
		bottom: 20px;
		#{$right}: 20px;
		#{$left}: 20px;
		@include respond-to('small') {
			position: static;
			height: auto;
			background-color: transparent;
			padding: side-values(5px 10px 8px 20px);
		}
	}

	&__title {
		font-size: 28px;
		font-weight: 800;
		color: #000;
		@include respond-to('small') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
		}
	}
}
