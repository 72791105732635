.wrap-content {
	padding: 20px 0;
}

.wrap-all-content-not-banner {
	margin-top: 140px;
}

.title {
	font-size: 40px;
	font-weight: 300;
	line-height: 1;
	color: #165683;
	margin: 15px 0 30px;
}

.hr-content {
	border-top: 1px solid #d9d9d9;
	margin: 0 0 20px;
}

.content-text {
	h2,
	h3 {
		font-size: 30px;
		font-weight: 700;
		line-height: 1.2;
		color: #002e62;
		margin: 10px 0 ;
		@include respond-to('large') {
			font-size: 26px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 20px;
		font-weight: 300;
		color: #6f6f6f;
		line-height: 1.2;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}
.box-shadow{
  -webkit-box-shadow: -24px 0px 40px -19px rgba(0,0,0,0.25);
  -moz-box-shadow: -24px 0px 40px -19px rgba(0,0,0,0.25);
  box-shadow: -24px 0px 40px -19px rgba(0,0,0,0.25);

}
.ul-side{
  font-size: 25px;
  color: #6f6f6f;
  padding-top: 20px;

}
.ul-side-li:first-child{
  color: #002e62;
}
.ul-side-li{
  

  margin-right:25px;
  margin-left: 30%;

  padding: 15px 0 15px;
  border-bottom: 1px solid #6f6f6f;
}
.ul-side-li:hover{
  color: #002e62;
  border-bottom: 1px solid #002e62;
}
.ul-side-li.active{
  color: #002e62;
  border-bottom: 1px solid #002e62;
}
#sb-site, .sb-site-container {
  
  min-height: 0;
}


.inline-display{
	display: inline-block;
}

.h1-level{
	font-size: 29px;
	font-weight: 600;
	color: #264670;
}
.right-r{
	font-size: 20px;
	font-weight: 600;
	color: #264670;

}
.one-row{
	margin-right: -15px;
}
.right-l{
	font-size: 20px;

}
.bg-img{
	background-position: 50%;
	-webkit-background-size: cover;
	background-size: cover;
	height: 450px; 
}

.hr-homepage-level {
	height: 19px;
	background: #002e62;
	border-top: 1px solid #fff;
	border-bottom: 4px solid #fff;
}
.under-level .col-md-4{
	border-left: 5px solid #fff;
	padding: 0;
}   
.box-shadow-level.col-md-6.col-xs-12 {
	border-bottom: 15px solid #002e62;
	margin-bottom: 4px;
}
.bg-img-left{
	height: 465px;
}
.padding-right-high{
	padding-right: 2%;
}
@media screen and (min-width: 1402px) {	
	.padding-right-high{
		padding-right: 30%;
	}
}


