.ftr-form {
	@include respond-to('small') {
		text-align: center;
		margin-bottom: 20px;
	}
	.has-feedback .form-control {
		padding-left: 12px;
		padding-right: 12px;
	}
}

.ftr-form-title {
	margin-bottom: 25px;
	& > p {
		font-size: 26px;
		color: #002e62;
		line-height: 0.8;
		margin: 0;
		@include respond-to('larger') {
			font-size: 24px;
		}
		& > span {
			font-size: 18px;
			color: #000;
			font-weight: 300;
		}
	}
}

.newslatter-input {
	width: 253px;
	height: 38px;
	border: 1px solid #bebebe;
	background-color: #eeeeee;
	position: relative;
	color: #000;
	font-size: 18px;
	font-weight: 300;
	padding: 5px 15px;
	border-radius: 0;
	box-shadow: none;
	@include respond-to('small') {
		margin-left: auto;
		margin-right: auto;
	}
	&:focus {
		outline: none;
		&::placeholder {
			opacity: 0;
		}
	}
	&::placeholder {
		color: #000;
		font-size: 18px;
		font-weight: 300;
		transition: opacity 0.4s ease;
	}
}

.newslatter-btn {
	margin-top: 22px;
	display: block;
	width: 253px;
	height: 38px;
	font-size: 18px;
	color: #fff;
	font-weight: 300;
	border: 0;
	background-color: #002e62;
	position: relative;
	@include respond-to('small') {
		margin-left: auto;
		margin-right: auto;
	}
	&:focus {
		outline: none;
	}
	&:active {
		@include btn-effect-active;
	}
	& > i {
		font-size: 18px;
		margin-#{$right}: 5px;
		transition: all 0.4s;
	}
	&:hover {
		& > i {
			transform: translateX(-4px);
		}
	}
}

.ftr-check-uncheck {
	position: relative;
	width: 23px;
	height: 23px;
	display: inline-block;
	margin-#{$left}: 5px;
	label {
		width: 23px;
		height: 23px;
		cursor: pointer;
		position: absolute;
		background-color: #eeeeee;
		border: 1px solid #bebebe;
		#{$left}: 0;
		top: 0;
		&:after {
			content: '';
			width: 12px;
			height: 6px;
			position: absolute;
			top: 6px;
			#{$left}: 5px;
			border: 2px solid #000;
			border-top: none;
			border-right: none;
			background: 0 0;
			opacity: 0;
			transform: rotate(-45deg);
		}
	}
	input[type=checkbox] {
		visibility: hidden;
		&:checked + label:after {
			opacity: 1;
		}
	}
}

.ftr-checkbox-para {
	display: inline-block;
	& > p {
		font-size: 16px;
		font-weight: 300;
		color: #000;
		margin: 0;
		@include respond-to('larger') {
			font-size: 13px;
		}
	}
}