.wrapper-banners {
	position: relative;
  width: 100%;
  margin-top: 135px;
	height: 816px;
	overflow: hidden;
	@include respond-to('huge') {
		height: 650px;
	}
	@include respond-to('large') {
		height: 450px;
	}
	@include respond-to('extra-small') {
		height: 300px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 800px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('huge') {
		min-height: 650px;
	}
	@include respond-to('large') {
		min-height: 450px;
	}
	@include respond-to('extra-small') {
		min-height: 300px;
	}
}

.box-slider{ 
  height: 132px;
  @include respond-to('medium') {
    height: 79px;
	}
  background: rgb(0, 46, 98);
  background: rgba(0, 46, 98, 0.71);
  // opacity: 0.7;
  position: absolute;
  bottom: 0px;
  left: 0; 
  right: 0;
}
.box-slider-item{
 cursor: pointer;
  // max-width: 150px;
  padding: 20px;
  @include respond-to('medium') {
    padding: 8px 20px;
	}
  border-right: 1px solid #002e62;
  border-left: 1px solid #002e62;
}

.height-full {
  height: 100%;
}
.box-slider-img{
  width: 100%;
  padding: 0px 12px ;
  transition: 0.5s;
  @include respond-to('medium') {
    // display: none !important;  
	}

}
.box-slider-text{
  color: #fff;
  font-size: 19px;
  padding: 2px;
  line-height: 1;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 40px;
  
  opacity: 0;
  text-decoration: none;
  transition: 0.5s;
  @include respond-to('medium') {
    margin-bottom: 0px;
    padding-top: 0px;
    opacity: 1;
    font-size: 16px;
	}
}
.box-slider-item:hover{
  background: #002e62;
  .box-slider-img{
    padding: 0px 24px ;
    margin-bottom: 5px;

  }
  .box-slider-text{
    opacity: 1;
    padding-top: 2px;
  }
  a .box-slider-text{
    text-decoration: none; 
  }
}
a.box-slider-item:focus, a.box-slider-item:hover {
  // color: #23527c;
  text-decoration: none !important;
  // .box-slider-item {
  //   padding: 5px 20px;
  // }
}
.slider-text-homepage{
  position: absolute;
  #{$right}: 13%;
  top: 50%;
  transform: translateY(-50%);
  max-width: 1200px; 
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2; 
  color: #fff; 
  text-shadow: 0 3px 5px #000;
  @include respond-to('medium') {
    font-size: 25px;
	}
}
.slider-text-homepage-sub-title{
  font-size: 40px;
  @include respond-to('medium') {
    font-size: 15px;
	}  
  
}


@media screen and (max-width: 992px) {  
  [dir=rtl] .slick-next {
    left: 2px;
  }
  [dir=rtl] .slick-prev {
    right: 2px;
  }
}

