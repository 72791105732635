.lpage2 {
	.lwrapper-main {
		min-height: 900px;
		height: 100vh;
		position: relative;
		width: 100%;
		// overflow: hidden;
		@include respond-to('small') {
			height: unset;
			padding-bottom: 400px;
		}
		&__banner {
			position: fixed;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			@include respond-to('small') {
				position: fixed;
				height: 100vh;
			}
		}
		&__content {
			// position: fixed;
			position: absolute;
			left: 0;
			top: 0;
			height: auto;
			width: 28.5%;
			min-width: 400px;
			background-color: transparent;
			@include respond-to('1650') {
				width: 33%;
			}
			@include respond-to('larger') {
				width: 40%;
			}
			@include respond-to('large') {
				width: 45%;
			}
			@include respond-to('small') {
				position: relative;
				z-index: 1;
				left: 0;
				right: 0;
				top: 320px;
				width: 100%;
				max-width: 400px;
				min-width: unset;
				margin: 0 auto;
				// background-color: rgba($color: #fff, $alpha: 0.9);
				box-shadow: 0 0 25px 5px rgba($color: #000000, $alpha: 0.4);
			}
		}
	}

	.lbanner-item {
		width: 100%;
		min-height: 100vh;
		position: relative;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		@include respond-to('small') {
			min-height: unset;
			height: 100vh;
    }
    &__credit{
      position: absolute;
      top: 15px;
      #{$right}: 20px;
      font-size: 16px;
      color: #000;
      padding: 5px 10px;
      background: rgba(255,255,255,0.4);
  
    }
		&__label {
			position: absolute;
			top: 100px;
			#{$right}: 0px;
			max-width: 450px;
			width: 100%;
			max-height: 190px;
			overflow: hidden;
			// height: 180px;
			padding: side-values(30px 70px 40px 40px);
			opacity: 1;
			transition: opacity 0.2s ease;
			@include respond-to('1100') {
				top: 100px;
				#{$right}: 0px;
				max-width: 400px;
				padding: 30px;
			}
			@include respond-to('medium') {
				padding: 20px;
				max-width: 350px;
			}
			@include respond-to('small') {
				// bottom: auto;
				// top: 30px;
				// #{$right}: 0;
				// #{$left}: 0;
				position: static;
				margin: 15px auto;
				max-width: 350px;
			}
			@include respond-to('mobile-m') {
				padding: 10px;
			}
			&_2 {
				top: 270px;
				max-width: 590px;
				padding: side-values(40px 70px 40px 40px);
				@include respond-to('1100') {
					padding: 30px;
					max-width: 480px;
				}
				@include respond-to('medium') {
					top: 200px;
					padding: 20px;
					max-width: 350px;
				}
				@include respond-to('small') {
					// top: 150px;
				}
			}
			&_3 {
				max-width: 520px;
				width: 100%;
				top: auto;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				padding: 25px;
				@include respond-to('huge') {
					#{$left}: auto;
					#{$right}: 0;
				}
				@include respond-to('1100') {
					max-width: 450px;
					padding: 25px 30px;
				}
				@include respond-to('medium') {
					padding: 20px;
					max-width: 350px;
				}
				@include respond-to('small') {
					// left: 0;
					// right: 0;
					// opacity: 0;
				}
			}
		}
		&__title {
			line-height: 0.9;
			text-align: $right;
			font-size: 40px;
			font-weight: 600;
			@include respond-to('1100') {
				font-size: 35px;
			}
			@include respond-to('medium') {
				font-size: 25px;
			}
			@include respond-to('small') {
				text-align: center;
			}
			& span {
				display: block;
				font-weight: 900;
				font-size: 50px;
				@include respond-to('1100') {
					font-size: 40px;
				}
				@include respond-to('medium') {
					font-size: 30px;
				}
			}
			&_2 {
				font-weight: 900;
				font-size: 40px;
				@include respond-to('1100') {
					font-size: 35px;
				}
				@include respond-to('medium') {
					font-size: 25px;
				}
				& span {
					font-weight: 600;
					font-size: 40px;
					@include respond-to('1100') {
						font-size: 35px;
					}
					@include respond-to('medium') {
						font-size: 25px;
					}
				}
			}
			&_3 {
				font-weight: 900;
				font-size: 25px;
				text-align: center;
				@include respond-to('1100') {
					text-align: $right;
				}
				@include respond-to('medium') {
					font-size: 20px;
				}
				@include respond-to('mobile-l') {
					text-align: center;
				}
			}
		}
	}

	.lmain-content {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.lside-content {
		position: relative;
		max-width: 415px;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		padding: 0 25px 60px;
		@include respond-to('small') {
			padding: 0 17px 10px;
			height: auto;
		}
		&__logo {
			position: relative;
			top: -5px;
			text-align: center;
			max-width: 270px;
			margin: 0 auto 25px;
			& img {}
		}
		&__text {}
		&__form {}
		&__logo-bottom {
			position: static;
      width: 100%;
      margin: 10px auto 5px;
      text-align: center;
      & p{
        font-size: 14px !important; margin-top: 10px; padding-right: 10px;
      }
			@include respond-to('small') {
				padding-top: 15px;
			}
			@include respond-to('mobile-l') {
				position: static;
			}
			& a {
				display: inline-block;
			}
		}
	}

	.llogo {
		text-align: center;
		&__svg {}
		&__img {
			position: absolute;
			top: 10px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		&__text {
			position: relative;
			font-size: 16px;
			font-weight: 600;
			&::after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				bottom: -13px;
				width: 135px;
				height: 1px;
				background-color: #b6a473;
			}
		}
	}

	.lcontent-text {
		color: #212a62;
		text-align: center;
		& h2,
		& h1,
		& h3 {
			margin: 0;
			line-height: 1;
			font-size: 30px;
		}
		& p {
			font-size: 20px;
			font-weight: 400;

		}
	}

	.lheader-text {
		margin-bottom: 20px;
		font-size: 30px;
		font-weight: 900;
		line-height: 1;
		& span {
			display: block;
			font-size: 20px;
			font-weight: 400;
		}
	}

	& .custom-form {
		.ftr-check-uncheck {
			margin: 15px 0 10px;
			label {
				background-color: transparent;
				border: 3px solid #212a61;
				&:after {
					border: 2px solid #212a62;
					border-top: none;
					border-#{$right}: none;
				}
			}
			input[type=checkbox] {
				visibility: hidden;
				&:checked + label:after {
					opacity: 1;
				}
			}
		}

		.form-control {
			color: #212a61;
			border-bottom: 1px solid #8b6f33;
			&::placeholder {
				color: #212a61;
			}
		}

		.customCheckboxText {
			text-align: $right;
			float: $right;
			margin-#{$right}: 9px;
			margin-top: 10px;
			font-size: 14px;
			font-weight: 600;
			color: #212a62;
			max-width: 85%;
		}

		.btn-form {
			height: 50px;
			font-size: 25px;
			font-weight: 900;
			color: #fff;
			background-color: #27306c;
			// &::after {
			// 	content: '';
			// 	position: absolute;
			// 	left: 50%;
			// 	top: 50%;
			// 	box-shadow: 0 0 60px 20px white;
			// 	width: 1px;
			// 	height: 1px;
			// }
		}
	}

}

.lpage2 {
	&.scrolling {
		& .lbanner-item__label {
			@include respond-to('small') {
				opacity: 0;
			}
		}
	}
}

.slick-next:before, .slick-prev:before{
  opacity: .85;
  font-size: 50px;
}
.slick-next, .slick-prev{
  width: 50px;
  height: 50px;
}
#lhp-banners2{
  .slick-next, .slick-prev{
    top: 70%;
  }
}
#lhp-banners3{
  .slick-next, .slick-prev{
    top: 80px;
    @include respond-to('medium') {
      top: 50%;
    }
  }
}

[dir=rtl] {
  #lhp-banners2{
    .slick-next{
      left: 27%;
      @include respond-to('1650') {
				left: 32%;
			}
      @include respond-to('larger') {
				left: 38%;
			}
      @include respond-to('large') {
				left: 43%;
			}
      @include respond-to('medium') {
				left: 50%;
			}
      @include respond-to('small') {
				left: 25px;
			}
    }
  }
  #lhp-banners3{
    .slick-next{
      left: 60px;
      @include respond-to('small') {
				left: 25px;
			}
    }
    .slick-prev{
      left: 120px;
      right: auto;
      @include respond-to('1650') {
			}
      @include respond-to('1550') {
				// right: 37%;
			}
      @include respond-to('huge') {
				// right: 40%;
			}
      @include respond-to('larger') {
				// right: 43%;
			}
      @include respond-to('large') {
				// right: 47%;
			}
      @include respond-to('medium') {
        right: 50%;
        left: auto;
			}
      @include respond-to('small') {
        right: 25px;
        left: auto;
			}
    }
  }
  #lhp-banners{
    .slick-next{
      left: 30%;
      @include respond-to('1650') {
				left: 35%;
			}
      @include respond-to('larger') {
				left: 42%;
			}
      @include respond-to('large') {
				left: 47%;
			}
      @include respond-to('medium') {
				left: 53%;
			}
      @include respond-to('small') {
				left: 25px;
			}
    }
  }

}

@include respond-to('small') {
  #lhp-banners2{
    position: static;
    height: 350px;
    & .slick-track{
      padding-bottom: 115px;
    }
    & .lbanner-item{
      height: 350px;
    }
    & .lbanner-item__label{
      position: absolute;
      bottom: -115px;
      top: auto;
      z-index: 9; 
      width: 100%;
      height: 115px;
    }
    & .slick-next, .slick-prev{
      top: 15%;
    }
  }
  
  .lpage2.scrolling #lhp-banners2 .lbanner-item__label{
    opacity: 1 !important;
  }
  
  .lpage2 .lwrapper-main__content{
    max-width: 100%;
    top: 115px;
  }

  .lpage2 .lside-content{
    max-width: 100%;
  }

  .lpage2 .lbanner-item__label_3{
    max-width: 100%;
    height: 60px !important;
  }

  .lpage2 .lbanner-item__label_2{
    max-width: 100%;
    height: 80px !important;
    bottom: -70px !important;
  }

  #lhp-banners2 .lbanner-item__label:first-child{
    display: none;
  }

  .lpage2 .lside-content{
    padding: 15px 17px 10px;
  }

  .lpage2 .lwrapper-main{
    height: auto;
    min-height: unset;
    padding-bottom: 0;
  }

}

// #############################################################################
.lpage4{
    .form-control {
		box-shadow: none;
		background-color: transparent;
		color: #a1a1a1;
		font-size: 18px;
		font-weight: 400;
		padding: 5px 0px;
		border: none;
		border-bottom: 1px solid #a1a1a1;
		border-radius: 0px;
		width: 100%;
		height: 40px;
		&:focus {
			outline: none;
			box-shadow: none;
			&::placeholder {
				opacity: 0;
				transition: opacity 0.3s ease;
			}
		}
		&::placeholder {
			color: #a1a1a1;
			font-size: 18px;
			opacity: 1;
			transition: opacity 0.3s ease;
		}

    }
    .btn-form{
        border-radius: 0;
    }
}
.lpage4 .custom-form .btn-form{
}
.lpage4 .custom-form .form-control{
  border-bottom-color: #fff !important;
  color: #fff !important;
}
.lpage4 .custom-form .form-control::placeholder{
    color: #fff !important;
  }
.lpage4 .custom-form .ftr-check-uncheck label:after{
  border-color: #fff !important;
}
.lpage4 .lbanner-item-image{
  height: 500px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
.lpage4 .lbanner-item-content{
    height: 100vh;
}
.lpage4 .lbanner-item__title{
  color: #000;
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 1.1;
  padding: 0 40px 0 300px;
}
.lpage4 .lbanner-item__title_2{
  font-weight: normal;
  font-size: 32px;
}
.lpage4 .lbanner-item-content__data{
  width: 75%;
  padding: 40px 0 40px 5px;
}
.lpage4 .lwrapper-main__banner{
  position: relative !important;
}
.lpage4 .lbanner-item-content__section360{
  background: #000;
  padding: 5px 10px;
  /*margin: 60px -40px 0 -78px;*/
  margin: 60px 0 0;
  position: relative;
}
.lpage4 .lbanner-item-content__section360:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: #e8ce84;
  width: 60%;
  height: 100%;
}
.lpage4 .lbanner-item-content__section360-title1{
  color: #fff;
  font-size: 35px;
  font-weight: normal;
  width: 40%;
  display: inline-block;
  position: relative;
}
.lpage4 .lbanner-item-content__section360-title1:after{
  content: '';
  position: absolute;
  top: -5px;
  left: -6px;
  background-image: url('assets/images/iconstrip.png');
  width: 44px;
  height: 59px;
}
.lpage4 .lbanner-item-content__section360-title2{
  color: #000;
  font-size: 35px;
  font-weight: bold;
  width: 59%;
  display: inline-block;
  padding-right: 15px;
  position: relative;
  z-index: 1;
}
.lpage4 .custom-form .ftr-check-uncheck label{
  border-width: 1px;
}
.lpage4 .custom-form .customCheckboxText{
  margin-top: 13px;
  font-size: 13px;  
  font-weight: 400;
}
@media(max-width: 1600px){
  .lpage4 .lbanner-item-content__data{
    width: 71%;
  }
   .lpage4 .lbanner-item__title{
    padding: 0 15px;
    font-size: 28px !important;
  }
  .lpage4 .lbanner-item-content__section360-title2{
    font-size: 22px;
  }
  .lpage4 .lbanner-item-content__section360-title1{
     font-size: 22px;
  }
  .lpage4 .lbanner-item-content__section360-title1:after{
    height: 50px;
  }
}
@media(max-width: 1500px){
  .lpage4 .lbanner-item-content__data{
    width: 70%;
  }
  .lpage4 .lbanner-item__title{
    padding: 0 40px 0 200px;
  }
}
@media(max-width: 1300px){
  .lpage4 .lbanner-item-content__data{
    width: 64%;
  }
  .lpage4 .lbanner-item__title{
    padding: 0 20px;
  }
}
@media(max-width: 1100px){
  .lpage4 .lbanner-item-content__data{
    width: 58%;
  }
 
}
@media(max-width: 768px){
  .lpage4 .lbanner-item-content__data{
    width: 100%;
  }
  .lpage4 .lwrapper-main__content{
    top: 0;
  }
  .lpage4 .lbanner-item,
  .lpage4 .lwrapper-main__banner,
  .lpage4 .lbanner-item-content{
    height: auto;
  }
  .lpage4 .lbanner-item-image{
    height: 200px;
  }
  .lpage4 .slick-next,
  .lpage4 .slick-prev{
    top: 19%;
  }
  .lpage4 .lbanner-item-content__section360-title1:after,
  .lpage4 .lbanner-item-content__section360:after{
    display: none;
  }
  .lpage4 .lbanner-item-content__section360-title1,
  .lpage4 .lbanner-item-content__section360-title2{
    width: 100%;
    padding: 5px;
    text-align: center;
  }
  .lpage4 .lbanner-item-content__section360-title2{
    background: #e8ce84;
    padding: 9px;
  }
  .lpage4 .lbanner-item-content__section360-title1{
    background: #000;
  }
  .lpage4 .lbanner-item-content__section360{
    background: transparent;
  }
}